import React, { useEffect, useState } from 'react';
import './styles/style.css'

function FirstBlock() {
    return (
        <div className="first-block">
            <h2>Первый блок</h2>
            <p>Содержимое первого блока</p>
        </div>
    );
}

export default FirstBlock;