import React from 'react';
import './styles/gameListStyle.css'

function GameCard({ game, onClick }) {
    return (
        <div className="game-card" style={{backgroundImage: `url(${game.logo})`}} onClick={() => onClick(game)}>
            <p>{game.name}</p>
        </div>
    );
}

export default GameCard;