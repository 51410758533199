import React, { useEffect, useState } from 'react';
import './styles/style.css'
import tonImg from './pictures/ton_symbol.png'
import tonAgeProfile from './pictures/ton_age_logo.png'
import {getProfile} from "./logic/AuthService";
import DepositPopup from "./DepositPopup";

function ProfileBlock() {
    const [balance, setBalance] = useState(null);
    const [profileImgUrl, setProfileImgUrl] = useState(null);
    const [isDepositMenuOpen, setDepositMenuOpen] = useState(false);

    const handleOpenMenu = () => {
        setDepositMenuOpen(true);
    };

    const handleCloseMenu = () => {
        setDepositMenuOpen(false);
    };

    useEffect(() => {
        getProfile().then(r => {
            console.log(r);
            setBalance(r.balance);
            setProfileImgUrl(r.photo_url);
        });
    }, []);

    return (
        <div className="profile-block">
            <div className="level-block">
                <div className="level-info">
                    <h4>LVL 1</h4>
                </div>
                <div className="bar-back">
                    <div className="bar-front"></div>
                    <div className="bar-info">
                        <p>300/300</p>
                    </div>
                </div>
            </div>
            <div className="balance-block">
                <div className="ton-logo" style={{backgroundImage: `url(${tonImg})`}}></div>
                <div className="balance">
                    <h4>{balance !== null ? balance.toFixed(2) : '0.00'}</h4>
                </div>
                <div className="pic-small" onClick={handleOpenMenu} style={{backgroundImage: `url(https://img.icons8.com/sf-regular-filled/48/FFFFFF/plus-math.png)`,
                width: '22px',
                height: '22px'}}></div>
                <DepositPopup
                    isOpen={isDepositMenuOpen}
                    onClose={handleCloseMenu}
                    anchorElement={null}
                    items={null}
                />
            </div>
            <div className="profile-logo" style={{backgroundImage: `url(${profileImgUrl || tonAgeProfile})`}}>
            </div>
        </div>
    );
}

export default ProfileBlock;