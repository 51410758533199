import React, { useEffect, useState } from 'react';
import { callbackOnServer } from './logic/AuthService'

function TelegramContainer({ onAuth }) {
    const bot_username = process.env.REACT_APP_BOTNAME;
    useEffect(() => {
        window.onTelegramAuth = (user) => {
            callbackOnServer(user).then(r => {
                onAuth(true)
            })
        };

        console.log(bot_username);

        const script = document.createElement("script");
        script.async = true;
        script.src = "https://telegram.org/js/telegram-widget.js?22";
        script.setAttribute("data-telegram-login", bot_username);
        script.setAttribute("data-size", "large");
        script.setAttribute("data-onauth", 'onTelegramAuth(user)');
        script.setAttribute("data-request-access", "write");

        // Добавляем скрипт в DOM при монтировании компонента
        document.querySelector(".telegram-container").appendChild(script);

        // Удаление скрипта при размонтировании компонента
        return () => {
            /*document.querySelector(".telegram-container").removeChild(script);*/
        };
    }, []);

    return (
        <div className="telegram-container">
        </div>
    );
}

export default TelegramContainer;