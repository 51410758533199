import React, {useEffect, useRef, useState} from 'react';
import './styles/gameListStyle.css'
import GameCard from "./GameCard";
import {Link, Route} from 'react-router-dom'

function GameList({ name, logo }) {
    // Создаем контекст для всех изображений в папке
    const imagesContext = require.context('./pictures/games/', false, /\.(png|jpe?g|webp)$/);

    // Получаем список файлов из контекста
    const imageFiles = imagesContext.keys();

    // Импортируем изображения и создаем объект с путями
    const images = imageFiles.reduce((acc, path) => {
        const imageName = path.replace('./', '').replace(/\.(png|jpe?g|webp)$/, ''); // Получаем имя файла без расширения
        acc[imageName] = imagesContext(path);
        return acc;
    }, {});

    const [games, setGames] = useState([]);
    const mockGames = [
        {
            name: "Jasmine`s Treasure",
            logo: images['jasmine_s_treasure'],
            url: "https://cdn.zilliongames.io/jasminestreasures/latest/index.html?sk=1J7VBOF89HIOPR1FM"
        },
        {
            name: "Buffalo's Sun",
            logo: images['buffalo_s_sun'],
            url: "https://cdn.zilliongames.io/buffalosun/latest/index.html?sk=CUE3I2I1CUPCC8TLZ"
        },
        {
            name: "Egypt`s Sun",
            logo: images['egypt_s_sun'],
            url: "https://cdn.zilliongames.io/egyptsun/latest/index.html?sk=ESE8YX2SPAPYFHORB"
        },
        {
            name: "Storm Joker",
            logo: images['storm_joker'],
            url: "https://cdn.zilliongames.io/stormjoker/latest/index.html?sk=MASSYJUDUCZP5Y650"
        },
    ];
    const containerRef = useRef(null);

    const handleCardClick = (game) => {
        console.log(`Выбрана игра: ${game.name}`, game.url);
        window.location.replace(game.url);
    };

    const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

    useEffect(() => {
        setGames(mockGames);

        const container = containerRef.current;
        let isMouseOver = false;
        let mousePosX = 0;

        const handleScroll = (direction) => {
            const scrollAmount = 5; // Измените скорость прокрутки по вашему усмотрению
            const containerRect = container.getBoundingClientRect();
            const nearLeftEdge = mousePosX < containerRect.left + 250;
            const nearRightEdge = mousePosX > containerRect.right - 250;

            const distanceToEdge = nearLeftEdge ? mousePosX - containerRect.left : containerRect.right - mousePosX;
            const maxDistance = 150; // Максимальное расстояние для максимальной скорости
            const speedMultiplier = distanceToEdge / maxDistance;

            const speed = clamp(scrollAmount / speedMultiplier, 0, 25);
            console.log(speed);

            if (nearRightEdge) {
                container.scrollLeft += speed;
            } else if (nearLeftEdge) {
                container.scrollLeft -= speed;
            }
        };

        const handleMouseMove = (event) => {
            const { clientX } = event;
            mousePosX = clientX;
        }

        const handleMouseEnter = () => {
            if (window.Telegram.WebApp.initData) { return; }
            isMouseOver = true;

            const scrollInterval = setInterval(() => {
                if (isMouseOver) {
                    handleScroll('right');
                } else {
                    clearInterval(scrollInterval);
                }
            }, 20);
        };

        const handleMouseLeave = () => {
            isMouseOver = false;
        };

        container.addEventListener('mouseenter', handleMouseEnter);
        container.addEventListener('mouseleave', handleMouseLeave);
        container.addEventListener('mousemove', handleMouseMove);

        return () => {
            container.removeEventListener('mouseenter', handleMouseEnter);
            container.removeEventListener('mouseleave', handleMouseLeave);
            container.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <div ref={containerRef} className="game-list-container">
            {games.map((game, index) => (
                <GameCard key={index} game={game} onClick={handleCardClick} />
            ))}
        </div>
    );
}

export default GameList;