import React, { useEffect, useState } from 'react';
import './styles/style.css'
import HeaderBlock from "./HeaderBlock";
import FirstHorizontalBlock from "./FirstHorizontalBlock";
import SecondHorizontalBlock from "./SecondHorizontalBlock";
import ThirdHorizontalBlock from "./ThirdHorizontalBlock";

function MainBlock() {
    return (
        <div className="main-block">
            <HeaderBlock />
            <FirstHorizontalBlock />
            <SecondHorizontalBlock />
            <ThirdHorizontalBlock />
        </div>
    );
}

export default MainBlock;