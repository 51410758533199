import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

const DepositPopup = ({ isOpen, onClose, anchorElement, items }) => {
    const [position, setPosition] = useState({ top: 0, left: 0 });

    useEffect(() => {
        const calculatePosition = () => {
            // Вычислите позицию по центру экрана
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;

            const topPosition = screenHeight / 2;
            const leftPosition = screenWidth / 2;

            setPosition({ top: topPosition, left: leftPosition });
            console.log(topPosition, leftPosition)
        };

        if (isOpen) {
            calculatePosition();
        }
    }, [isOpen]);

    if (!isOpen) {
        return null;
    }

    const closeMenu = () => {
        onClose();
    };

    return ReactDOM.createPortal(
        <div
            style={{
                width: '450px',
                height: '400px',
                position: 'fixed',
                top: position.top,
                left: position.left,
                transform: 'translate(-50%, -50%)',
                border: '1px solid #ccc',
                padding: '10px',
                backgroundColor: 'white',
            }}
        >
            <ul>
                {/*{items.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}*/}
            </ul>
            <button onClick={closeMenu}>Close</button>
        </div>,
        document.body
    );
};

export default DepositPopup;
