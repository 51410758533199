import React, { useEffect, useState } from 'react';
import './styles/style.css'

function SecondHorizontalBlock() {
    return (
        <div className="second-horizontal-block">
            <h2>Второй горизонтальный блок</h2>
            <p>Содержимое второго горизонтального блока</p>
        </div>
    );
}

export default SecondHorizontalBlock;