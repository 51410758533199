import React, { useEffect, useState } from 'react';
import './styles/style.css'
import ProfileBlock from "./ProfileBlock";
import TelegramContainer from "./TelegramContainer";
import { checkAuth, checkAuthMiniApp } from "./logic/AuthService";

function HeaderBlock() {
    const [isAuth, setAuth] = useState(false);
    const [isLoading, setLoading] = useState(true);

    const changeAuthState = (state) => {
        setAuth(state);
    }

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initData) {
            checkAuthMiniApp(window.Telegram.WebApp.initData).then(r => {
                console.log(window.Telegram.WebApp.initDataUnsafe);
                changeAuthState(r);
                setLoading(false);
            })
        } else {
            checkAuth().then(r => {
                changeAuthState(r);
                setLoading(false);
            })
        }

        return () => {
        };
    }, []);

    return (
        <div className="header-block">
            {isLoading ? (
                <div className="loading-profile"></div>
            ) : !isAuth ? (
                <TelegramContainer onAuth={(state) => setAuth(state)} />
            ) : (
                <ProfileBlock />
            )}
        </div>
    );
}

export default HeaderBlock;