const apiUrl = process.env.REACT_APP_HOST_IP_ADDRESS;
const botApiUrl = process.env.REACT_APP_BOT_SERVICE_IP_ADDRESS;

export async function getProfile() {
    try {
        const token = localStorage.getItem('authToken');

        const params = {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                'ngrok-skip-browser-warning': '69420',
            },
        };

        const response = await fetch(
            apiUrl + "user/getUserProfile",
            params
        );

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();

        return data;
    } catch (e) {
        console.error("Error sending data to the server", e);
    }
}

export async function checkAuthMiniApp(info) {
    try {
        const params = {
            method: "POST",
            body: info,
            headers: {
                "Content-Type": "application/json",
                'ngrok-skip-browser-warning': '69420',
            }
        };

        const response = await fetch(
            botApiUrl + "telegram/miniAppAuth",
            params
        );

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();

        const errorCode = data.errorCode;
        const token = data.token;

        console.log(errorCode, token);

        if (errorCode === 0) {
            localStorage.setItem('authToken', token);
            return true;
        }
    } catch (e) {
        console.error("Error sending data to the server", e);
    }
}

export async function checkAuth(){
    try {
        const token = localStorage.getItem('authToken')

        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'ngrok-skip-browser-warning': '69420',
        };

        const params = {
            method: "GET",
            headers: headers
        };

        const response = await fetch(
            apiUrl + "user/check",
            params
        );

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();

        const errorCode = data.errorCode;
        const callbackToken = data.token;

        console.log(errorCode, callbackToken);

        if (errorCode === 0) {
            localStorage.setItem('authToken', callbackToken);
            return true;
        }
    } catch (e) {
        console.error("Error sending data to the server", e);
    }
}

export async function callbackOnServer(info) {
    try {
        const params = {
            method: "POST",
            body: JSON.stringify(info),
            headers: {
                "Content-Type": "application/json",
                'ngrok-skip-browser-warning': '69420',
            }
        };

        const response = await fetch(
            botApiUrl + "telegram/auth",
            params
        );

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();

        const errorCode = data.errorCode;
        const token = data.token;

        console.log(errorCode, token);

        if (errorCode === 0) {
            localStorage.setItem('authToken', token);
        }
    } catch (e) {
        console.error("Error sending data to the server", e);
    }
}