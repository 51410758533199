import React, { useState } from 'react';
import FirstBlock from "./FirstBlock";
import MainBlock from "./MainBlock";
import ThirdBlock from "./ThirdBlock";
import OverlayInfo from "./OverlayInfo";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = (status) => {
    setIsLoggedIn(status);
  }

  return (
      <div className="app-body">
          {/*<Login onLogin={(userInfo) => logInPipeLine(userInfo, () => handleLogin(true))} />*/}
          <FirstBlock />
          <MainBlock />
          <ThirdBlock />
          <OverlayInfo />
      </div>
  );
}

export default App;

function logInPipeLine({ email, password }, on) {
    console.log(email, password, on);
    on()
}
