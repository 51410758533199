import React, { useEffect, useState } from 'react';
import './styles/style.css'
import './styles/gameListStyle.css'
import GameList from "./GameList";

function ThirdHorizontalBlock() {
    return (
        <div className="third-horizontal-block">
            <div className="game-category">
                <h2>New Arrivals</h2>
                <GameList />
            </div>
            <div className="game-category">
                <h2>TonAge Games</h2>
                <GameList />
            </div>
        </div>
    );
}

export default ThirdHorizontalBlock;