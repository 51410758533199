import React, {useEffect, useRef, useState} from 'react';
import './styles/style.css'
import jasmineBackLogo from './pictures/jasm_logo_back_part.png'
import jasmineFrontLogo from './pictures/jasm_logo_front_part.png'

function FirstHorizontalBlock() {
    const scrollRef = useRef();

    const scrollToBottom = () => {
        scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        const gamesBlock = document.querySelector('.game-category');
        scrollRef.current = gamesBlock;
    }, []);

    return (
        <div className="first-horizontal-block">
            <div className="main-game-preview-block" style={{backgroundImage: `url(${jasmineBackLogo})`}}>
                <div className="logo-text-part">
                    <p>PLAY & GET REWARD</p>
                    <h1 style={{fontSize: '40px'}}>PLAY TO EARN</h1>
                    <p>Dive into our wide range of games</p>
                    <button onClick={scrollToBottom} className="glass-button">Get Started</button>
                </div>
                <div className="logo-front-part"
                     style={{backgroundImage: `url(${jasmineFrontLogo})`}}></div>
            </div>
        </div>
    );
}

export default FirstHorizontalBlock;