import React, { useEffect, useState } from 'react';
import './styles/style.css'

function ThirdBlock() {
    return (
        <div className="third-block">
            <h2>Третий блок</h2>
            <p>Содержимое третьего блока</p>
        </div>
    );
}

export default ThirdBlock;