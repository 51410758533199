import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './styles/overlayInfo.css';

const OverlayInfo = () => {
    const [container] = useState(document.createElement('div'));

    useEffect(() => {
        document.body.appendChild(container);
        return () => {
            document.body.removeChild(container);
        };
    }, [container]);

    const tag = process.env.REACT_APP_GIT_TAG;
    const branch = process.env.REACT_APP_GIT_BRANCH;
    const commitCount = process.env.REACT_APP_GIT_COUNT;
    const test = process.env.REACT_APP_GIT_TEST;

    return ReactDOM.createPortal(
        <div className="overlay-info">
            Development Build: {tag}-{branch}-{commitCount} {test}
        </div>,
        container
    );
};

export default OverlayInfo;
